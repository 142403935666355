import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { FollowButtonComponent } from '@components/follow-button.component';
import { IonAvatar, IonItem, IonLabel } from '@ionic/angular/standalone';
import { LoginModal } from '@modals/login.modal.component';
import { IState } from '@models';
import { IUserShort } from '@models/user';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ModalService } from '@services/modal.service';
import { CreateSourceStandalonePipe } from '@shared/pipes/create-source2.pipe';
import { UserNamePipe } from '@shared/pipes/user-name.pipe';
import { ActiveUserService } from '@shared/services/active-user.service';
import * as socialActions from '@store/actions/social';
import { getUserTypeAndId } from '@utils/users';
import { finalize, first } from 'rxjs';

@Component({
  selector: 'cs-user-item',
  standalone: true,
  imports: [
    CommonModule,
    CreateSourceStandalonePipe,
    UserNamePipe,
    FollowButtonComponent,
    IonItem,
    IonAvatar,
    IonLabel,
  ],
  template: `
    <ion-item button (click)="navigateToUserProfile(user)">
      <ion-avatar slot="start">
        <img
          [src]="user.AvatarUrl | createSource"
          src-fallback="assets/images/user-image.png"
        />
      </ion-avatar>
      <ion-label>
        <h2 class="ion-text-bold">{{ user | userName }}</h2>
        <p *ngIf="event">
          {{ event | date : 'MMM d, y, HH:mm' }}
        </p>
      </ion-label>
      <follow-button
        *ngIf="activeUserId !== (user.UserId || user.UUID)"
        slot="end"
        [isFollowed]="user.Followed"
        [isLoading]="isLoading"
        (toggle)="toggle($event)"
      ></follow-button>
    </ion-item>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    `,
  ],
})
export class UserItemComponent implements OnInit {
  @Input() user: IUserShort;
  @Input() title: string;
  @Input() event: string;
  @Output() toggleFollow = new EventEmitter<boolean>();
  isLoading = false;

  activeUserId: string | null = null;

  constructor(
    private router: Router,
    private activeUserService: ActiveUserService,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    private store: Store<IState>,
    private readonly actions$: Actions
  ) {}

  ngOnInit(): void {
    this.activeUserId = this.activeUserService.getID();
  }

  navigateToUserProfile(user: IUserShort) {
    const [userType, id] = getUserTypeAndId(user);
    this.router.navigate(['tabs', 'social', userType, id]);
  }

  async openLoginModal() {
    const modal = await this.modalService.createCSModal(LoginModal, {});
    return modal.present();
  }

  toggle(e: Event) {
    e.stopPropagation();
    if (!this.activeUserService.isAuthenticated()) {
      this.openLoginModal();
      return;
    }
    const [userType, userId] = getUserTypeAndId(this.user);

    // this.isFollowingMap[userId] = true;
    this.isLoading = true;
    this.cdr.detectChanges();

    this.actions$
      .pipe(
        ofType(
          socialActions.SocialPostFollowToggleSuccess,
          socialActions.SocialPostFollowToggleError
        ),
        first(),
        finalize(() => {
          this.isLoading = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe(({ type, isFollowed }) => {
        if (type === socialActions.SocialPostFollowToggleSuccess.type) {
          this.toggleFollow.emit(!isFollowed);
        }
      });

    this.store.dispatch(
      socialActions.SocialPostFollowToggleRequest({
        userType,
        userId,
        isFollowed: this.user.Followed,
      })
    );
  }
}
